import "./locationssection.scss"
import * as React from "react"
import { Col, Container } from 'react-bootstrap'
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Link as GatsbyLink} from "gatsby"

const LocationsSection = () => {
    const { t } = useTranslation()
    const locationsMap = [
        { city: "San Francisco, CA", country: "USA", url: "/careers/location-usa/", key: "sanfrancisco" },
        { city: "Elgin, IL", country: "USA", url: "/careers/location-usa/", key: "elgin" },
        { city: "Tepatitlan", country: "Mexico", url: "/careers/location-mexico/", key: "tepa" },
        { city: "Guadalajara", country: "Mexico", url: "/careers/location-mexico/", key: "guadalajara" },
        { city: "Resistencia", country: "Argentina", url: "/careers/location-argentina/", key: "resistencia" },
        { city: "Cochabamba", country: "Bolivia", url: "/careers/location-bolivia/", key: "cochabamba" },
        { city: "La Paz", country: "Bolivia", url: "/careers/location-bolivia/", key: "lapaz" },
        { city: "Montevideo", country: "Uruguay", url: "/careers/location-uruguay/", key: "montevideo" },
        { city: "Tandil", country: "Argentina", url: "/careers/location-argentina/", key: "tandil" },
        { city: "Queretaro", country: "Mexico", url: "/careers/location-mexico/", key: "queretaro" },
        { city: "Valencia", country: "Spain", url: "/careers/location-spain/", key: "valencia" },
        { city: "Lima", country: "Peru", url: "/careers/location-peru/", key: "lima" },
        { city: "Tunisia", country: "Tunisia", url: "/careers/location-tunisia/", key: "tunisia" }
    ]

    let locationsMexico = locationsMap.filter(o => o.country === 'Mexico').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));

    let locationsUSA = locationsMap.filter(o => o.country === 'USA').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));

    let locationsArgentina = locationsMap.filter(o => o.country === 'Argentina').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));
    let locationsPeru = locationsMap.filter(o => o.country === 'Peru').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));
    let locationsBolivia = locationsMap.filter(o => o.country === 'Bolivia').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));
    let locationsSpain = locationsMap.filter(o => o.country === 'Spain').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));
    let locationsUruguay = locationsMap.filter(o => o.country === 'Uruguay').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));

    let locationsTunisia = locationsMap.filter(o => o.country === 'Tunisia').map(({ city, country, url, key }) => (
        <li key={key}>
            <span>{city}</span>

        </li>
    ));

    return (
        <>
            <section id="jobs" className="section-locations">
                <Container fluid="xl">
                    <h4 className="header-square green">{t("Locations")}</h4>
                    <ul className="row wrapper-locations justify-content-center">
                        <Col md={6} lg={3}>
                            <h3> USA </h3>
                            {locationsUSA}
                            <GatsbyLink to="/careers/location-usa/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                        <Col md={6} lg={3}>
                            <h3> Mexico </h3>
                            {locationsMexico}
                            <GatsbyLink to="/careers/location-mexico/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                        <Col md={6} lg={3}>
                            <h3> Argentina </h3>
                            {locationsArgentina}
                            <GatsbyLink to="/careers/location-argentina/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                        <Col md={6} lg={3}>
                            <h3> Uruguay </h3>
                            {locationsUruguay}
                            <GatsbyLink to="/careers/location-uruguay/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                        <Col md={6} lg={3}>
                            <h3> {t("Spain")} </h3>
                            {locationsSpain}
                            <GatsbyLink to="/careers/location-spain/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                        <Col md={6} lg={3}>
                            <h3> Bolivia </h3>
                            {locationsBolivia}
                            <GatsbyLink to="/careers/location-bolivia/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                        <Col md={6} lg={3}>
                            <h3> Peru </h3>
                            {locationsPeru}
                            <GatsbyLink to="/careers/location-peru/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                        <Col md={6} lg={3}>
                            <h3> Tunisia </h3>
                            {locationsTunisia}
                            <GatsbyLink to="/careers/location-tunisia/">{t("Careers")}<span className="arrow-right"></span></GatsbyLink>
                        </Col>
                    </ul>
                </Container>
            </section>
        </>
    )
}

export default LocationsSection
