/*
* Props
* reverse:  Boolean 
*/
import "./referafriend.scss"
import * as React from "react"
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import {useTranslation } from "gatsby-plugin-react-i18next"
import {Link as GatsbyLink} from "gatsby"
const ReferAFriend = ({ reverse = false }) => {
    const { t } = useTranslation()
    return (

        <section className="section-careers-refer">
            <Row className={reverse ? "flex-reverse" : "wrapper"}>
                <Col className="col-img" md={6}>
                    <StaticImage
                        className="d-md-none"
                        layout="fullWidth"
                        //height={352}
                        alt="Refer a friend"
                        src="../../images/refer/refer-a-friend-mobile@3x.png"
                        formats={["auto", "webp", "avif"]}
                    />
                    <StaticImage
                        className="d-none d-md-block d-xl-none"
                        layout="fullWidth"
                        //height={424}
                        alt="Refer a friend"
                        src="../../images/refer/refer-a-friend@3x.png"
                        formats={["auto", "webp", "avif"]}
                    />
                </Col>
                <Col md={6}>
                    <div className="col-text">
                        <h4 className="header-square green md-blue">{t("Refer a Friend")}</h4>
                        <p>
                            {t("Know someone who would be a great fit for BTS")}
                        </p>
                        <GatsbyLink className="link-dark-blue-btn w-half px-2" to="/refer-a-friend/">
                            {t("Refer Now")}
                        </GatsbyLink>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default ReferAFriend
