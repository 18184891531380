import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Row } from 'react-bootstrap'
import "./whybts.scss"

const WhyBTS = ({ reverse = false }) => {
    const { t, i18n } = useTranslation()

    const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "careers" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `);

    const image = data.allFile.edges.find(
        edge => edge.node.name === `bts-team-${i18n.language.toLowerCase() || "en"}`
    );
    const imagex2 = data.allFile.edges.find(
        edge => edge.node.name === `bts-team-${i18n.language.toLowerCase() || "en"}@2x`
    );
    const imagex3 = data.allFile.edges.find(
        edge => edge.node.name === `bts-team-${i18n.language.toLowerCase() || "en"}@3x`
    );
    const image4k = data.allFile.edges.find(
        edge => edge.node.name === `bts-team-4k-${i18n.language.toLowerCase() || "en"}`
    );
   

    return (
        <section id="why" className="section-careers-why">
            <Row className={reverse ? "flex-reverse" : "wrapper"}>
                <Col className="col-img" md={6} >
                    <GatsbyImage
                        className="d-md-none"
                        layout="fullWidth"
                        //height={580}
                        alt="Who we are"
                        image={getImage(image.node)}
                        formats={["auto", "png"]}
                    />
                    <GatsbyImage
                        className="d-sm-none medium"
                        layout="fullWidth"
                        //height={580}
                        alt="Who we are"
                        image={getImage(imagex2.node)}
                        formats={["auto", "png"]}
                    />
                    <GatsbyImage
                        className="d-none d-md-block d-xxl-none imgTablet"
                        layout="fullWidth"
                        //height={580}
                        alt="Who we are"
                        image={getImage(imagex3.node)}
                        formats={["auto", "png"]}
                    />
                    <GatsbyImage
                        className="d-none d-xxl-block"
                        layout="fullWidth"
                        //height={580}
                        alt="Who we are"
                        image={getImage(image4k.node)}
                        formats={["auto", "png"]}
                    />
                </Col>
                <Col md={6}>
                    <div className="col-text">
                        <h4 className="header-square green md-blue lg-large">{t("Why Join BTS")}</h4>
                        <ul>
                            <li>{t("Create your own career path")}</li>
                            <li>{t("Join a company that encourages collaboration and values diversity")}</li>
                            <li>{t("Get a competitive compensation package")}</li>
                            <li>{t("Flexible work-life balance")}</li>
                            <li>{t("Mentoring programs")}</li>
                            <li>{t("Training courses")}</li>
                            <li>{t("Relocation opportunities")}</li>
                            <li>{t("Participation in  the US stock option plan")}</li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default WhyBTS
